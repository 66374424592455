<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <div class="flex flex-col items-center justify-center h-1/2">
      <p>Tính năng đang được phát triển, sẽ sớm ra mắt trong thời gian tới</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TiktokShop',
};
</script>

<style></style>
